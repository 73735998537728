<script setup>
import { computed } from "vue";

const props = defineProps({
    size: {
        type: String,
        default: "lg",
    },
});

const sizeClass = computed(() => {
    return {
        sm: "px-2 py-1 text-xs",
        md: "px-3 py-2 text-xs",
        lg: "px-4 py-2 text-xs",
        xl: "px-4 py-2 text-sm",
    }[props.size];
});
</script>

<template>
    <button
        class="inline-flex items-center bg-gray-800 border border-transparent rounded-md font-semibold text-white uppercase tracking-widest hover:bg-gray-700 focus:bg-gray-700 active:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 transition ease-in-out duration-150"
        :class="sizeClass"
    >
        <slot />
    </button>
</template>
